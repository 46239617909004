import React from 'react'
import { navigate } from 'gatsby'

import './auth.scss'
import SignUpForm from 'components/Auth/SignUp'
import * as routes from 'constants/routes'
// import Layout from 'components/shared/Layout'
import Layout from 'layouts/Page'
import SEO from 'components/SEO/SEO'

class SignUpPage extends React.Component {
  onSubmit = async (email, password, fname, lname) => {
    try {
      const authUser = await this.props.auth.doCreateUserWithEmailAndPassword(
        email,
        password
      )

      await this.props.database.doCreateUser(
        authUser.user.uid,
        fname,
        lname,
        email
      )

      navigate(
        `/users/add_photo?redirectURL=${this.props.location.pathName ||
          routes.SEARCH}`,
        {
          replace: true,
          state: { ...(this.props.location.state || {}) },
        }
      )
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <div id="signup-page">
        <div className="content-wrap">
          <section className="page-title form-title">
            <h2>Sign Up</h2>
          </section>
          <SignUpForm onSubmit={this.onSubmit} />
        </div>
      </div>
    )
  }
}

export default ({ location }) => (
  <Layout
    navbar="auth"
    navbarProps={{ showSearchBar: false }}
    location={location}
    displayFooter={false}
  >
    <SEO title="Create an account" />
    <SignUpPage />
  </Layout>
)
