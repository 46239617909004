import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import './style.scss'
import * as routes from 'constants/routes'
import { button } from 'utils/styles'

const AuthBtn = styled('button')`
  display: flex;
  flex: 1;
  ${button.purple};
  ${button.big};
`

const INITIAL_STATE = {
  fname: '',
  lname: '',
  email: '',
  password: '',
  error: null,
}

export default class SignUpForm extends React.Component {
  state = {
    ...INITIAL_STATE,
  }

  onSubmit = event => {
    event.preventDefault()

    const { fname, lname, email, password } = this.state
    ;(async () => {
      const error = await this.props.onSubmit(email, password, fname, lname)
      if (error) {
        this.setState({ error })
      }
    })()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { fname, lname, email, password, error } = this.state

    const isInvalid =
      password === '' || fname === '' || lname === '' || email === ''

    return (
      <form
        action=""
        role="login-form"
        className="signup-form"
        id="login-form"
        onSubmit={this.onSubmit}
      >
        <section className="form-page-container">
          <div className="auth-page-subcontainer">
            <div className="auth-page-flex">
              <fieldset className="fieldset fieldset-with-label">
                <label htmlFor="fnme">First Name</label>
                <input
                  type="text"
                  autoFocus
                  required
                  maxLength="250"
                  name="fname"
                  id="user_first_name"
                  value={fname}
                  onChange={this.onChange}
                />
              </fieldset>

              <fieldset className="fieldset fieldset-with-label">
                <label htmlFor="lname">Last Name</label>
                <input
                  type="text"
                  required
                  name="lname"
                  id="user_last_name"
                  value={lname}
                  onChange={this.onChange}
                />
              </fieldset>
              <fieldset className="fieldset fieldset-with-label">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  required
                  name="email"
                  id="user_email"
                  value={email}
                  onChange={this.onChange}
                />
              </fieldset>
              <fieldset className="fieldset fieldset-with-label">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  required
                  name="password"
                  id="user_password"
                  value={password}
                  onChange={this.onChange}
                />
              </fieldset>
              <p className="hint-text">
                By signing up, you agree to Mellow's{' '}
                <a target="_blank" href="/legal/terms">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a target="_blank" href="/legal/privacy">
                  Privacy Policy
                </a>
                .
              </p>
              {error && <p>{error.message}</p>}
              <section className="auth-page-submit">
                <AuthBtn type="submit" value="Sign Up" role="signup">
                  Sign Up
                </AuthBtn>
              </section>
            </div>
            {/* TODO: Pass state through here */}
            <div>
              <span>Already have an account? </span>
              <Link to="/users/login">Sign In</Link>
            </div>
          </div>
        </section>
      </form>
    )
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={routes.SIGN_UP}>Sign Up</Link>
  </p>
)

export { SignUpLink }
